import React from "react";

import "../styles/centered-box.less";
import "../styles/home.less";
import "../components/layout.css";

import { Layout, Row, Col, Form, Button, Select } from "antd";
import {
  FireOutlined,
  ToolOutlined,
  MobileOutlined,
  TeamOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";

import HeaderPatinette from "../components/header";
import FooterPatinette from "../components/footer";
import queryString from "query-string";

import { navigate } from '../utils'

const { Option } = Select;

const { Content } = Layout;

const submitFirstAnswer = (values) => {
  let parsed = {};
  if (window) {
    parsed = queryString.parse(window.location.search);
  }
  navigate("/form1-identity", {
    state: {
      formValues: {
        ...values,
        ...(parsed.partner && { partner: parsed.partner }),
      },
    },
  });
};

const HomeForm = () => {
  const [form] = Form.useForm();
  const { getFieldError } = form;
  return (
    <Form form={form} onFinish={submitFirstAnswer}>
      <Row gutter={16} className="form-home">
        <Form.Item
          name="vehicle_type"
          rules={[{ required: true, message: "Requis" }]}
          labelCol={{ span: 24 }}
          label="Quel est votre moyen de déplacement urbain ?"
        >
          <Select
            placeholder="Je roule avec..."
            size="large"
            style={{ width: "200px" }}
          >
            <Option value="trottinette">Trottinette</Option>
            <Option value="gyroroue">Gyroroue</Option>
            <Option value="longboard">Longboard</Option>
            <Option value="hoverboard">Hoverboard</Option>
            <Option value="ultralightescooter">
              Scooter électrique ultra-léger
            </Option>
            <Option value="gyropode">Gyropode</Option>
          </Select>
        </Form.Item>
        <Form.Item span={24}>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            disabled={getFieldError("vehicle_type").length}
          >
            Je découvre mon prix
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};

export default function IndexPage() {
  return (
    <Layout className="centered-box">
      <HeaderPatinette />
      <Content>
        <Row className="content" type="flex" justify="center">
          <Col lg={18} xs={24} className="main">
            <h1>Vous bougez ? On assure !</h1>
            <Row>
              <Col lg={14} className="avantages">
                <p>
                  <FireOutlined />
                  Assurez votre trottinette électrique pour moins de 5€ et
                  évitez 500€ d’amende
                </p>
                <p>
                  <ToolOutlined />
                  On vous rembourse jusqu’à 75€ de bons d’achat dans votre
                  magasin préféré par an (réparation ou achats)!
                </p>
                <p>
                  <MobileOutlined />
                  Support 24/7 tel, chat et e-mail
                </p>
                <p>
                  <TeamOutlined />
                  La seule assurance qui couvre tous les engins de votre foyer
                  sans surcoût
                </p>
                <p>
                  <ThunderboltOutlined />
                  On couvre tous les engins électriques: trottinettes, skates,
                  gyroroues
                </p>
              </Col>
              <Col lg={10} xs={24} style={{ paddingTop: 30 }}>
                <HomeForm />
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
      <FooterPatinette />
    </Layout>
  );
}
